.about {
  background-color: #444;
}

.about .about-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

}

.about .about-left .mypic {
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  border: 2px solid var(--primary);
  box-sizing: border-box;
  margin-top: 2rem;
}

.about .about-left .mypic img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.about .about-left .nameinfo {
  margin-top: 1rem;
}

.about .about-left .nameinfo h2 {
  font-weight: 200;
  margin: 0.5rem 0 1rem;
  font-size: 2rem;
  text-align: center;
  font-style: italic;
  color: var(--primary);
}

.about .about-left .nameinfo h3 {
  text-align: center;
  font-weight: 600;
  margin: 0.2rem 0;
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-style: italic;

}

.about .about-left .hireme {
  margin: 2.5rem auto 1rem;
}

.about .about-left .hireme-wraper {
  position: relative
}

.about .about-left .hireme-wraper span {
  position: absolute;
  font-size: 10px;
  top: -1rem;
  right: -8.5rem;
  width: 8rem;
  padding: 6px;
  text-align: center;
  border-radius: 4px;
  box-sizing: border-box;
  color: #f1f1f1;
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 0;
  transition: all 3s ease;
}

.about .about-left .hireme-wraper:hover span {
  opacity: 1;
}


/*                          right side about          */


.about .right-side {
  padding-right: 4rem;
}

.about .right-side .about-info {
  padding: 0 1rem 0 0;
}

.about .right-side .about-info .iaminfo {
  text-align: center;
  margin-bottom: 1.5rem;
  font-style: italic;
  color: var(--primary);
}

.about .right-side .about-info .iaminfo h1,
.about .right-side .about-info .iaminfo h3 {
  font-weight: 200;
  margin-bottom: 0.5rem;
}

.about .right-side .about-info .aboutdetail p {
  text-align: justify;
  font-weight: 200;
  margin-bottom: 2rem;
  text-indent: 35px;
}

.about .right-side .about-info .infolist {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;

}

.about .right-side .about-info .infolist span {
  font-weight: 600;
  display: inline-block;
  margin-right: 0.5rem;
  font-style: italic;
  color: var(--primary);
}

.about .right-side .about-info .infolist h4 {
  color: #fff;
  font-weight: 100;
  font-size: 0.9rem;
}


@media (max-width: 930px) {
  .about .right-side {
    padding-right: 2rem;
  }

  .about .right-side .about-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

  }

  .about .right-side .about-info .infolist {
    flex-direction: column;
    padding-left: 4rem;
    margin: 1rem auto;
    padding-bottom: 2rem;

  }


  .about .right-side .about-info .infolist .infoleft,
  .about .right-side .about-info .infolist .inforight {
    text-align: left;
  }

  .about .about-left,
  .about .about-right {

    padding: 2rem 0.5rem;
    margin-top: 1rem;

  }

}

@media (max-width: 500px) {
  .about .right-side {
    padding-right: 0.5rem;
  }
}