@import url('https://fonts.googleapis.com/css2?family=Rouge+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

* {

  list-style: none;
  text-decoration: none;
  transition: all 0.3s ease;
}


.nav-container {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem 0 2rem;
  background-color: var(--primary);
  box-sizing: border-box;
  color: azure;
  transition: all 0.5s ease-in-out;
  z-index: 99;
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.3);

}

.nav-container .logo {
  font-size: 1.8rem;
  font-weight: 200;
  letter-spacing: 1px;
  transition: all 0.5s linear;
  cursor: pointer;
  color: #1e6cf4;
  font-family: "Lobster", sans-serif;
}

/*                                       color changer  */

.nav-container .color-wraper {
  position: absolute;
  top: -10rem;
  left: 5rem;
  opacity: 0;
  z-index: -1;
  transition: all 0.8s ease-in-out;
}

.nav-container .color-style {
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  display: flex;
  gap: 0.5rem;
  position: absolute;
  top: -5rem;
  left: -4rem;
  z-index: 10;
  transition: all 1s ease;


}

.nav-container .color-style span {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  cursor: pointer;

}

.nav-container .color-btn {
  margin: auto 1rem;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  position: fixed;
  right: 1rem;
  /* top: -1rem; */
  position: absolute;
}

.nav-container .color-btn:hover img {
  transform: scale(1.2);
}

.nav-container img {
  width: 100%;
  height: 100%;
}

.nav-container .color-wraper.show {
  top: 10rem;
  opacity: 1;
}

.nav-container .logo span {
  color: blue;
  font-size: 2.7rem;
  font-weight: 500;
  font-family: 'Rouge Script', cursive;
}

.nav-container .logo:hover {
  transform: scale(1.1);
  color: rgb(44, 44, 233);
}

.nav-container .menu {
  display: flex;
  margin-right: 3rem;

}

.nav-container .menu .icons {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.5rem;
  border-radius: 5px;
  vertical-align: middle;
  transition: all 1s ease-in-out;

}

.nav-container .menu .menu-wraper {
  display: flex;

}

.nav-container .menu .menu-item {
  font-size: 1.1rem;
  width: 100%;
  padding: 0.4rem 0.8rem;
  font-weight: 400;
  line-height: 1.8rem;
  margin: 0 0.1rem;
  text-transform: uppercase;
  transition: all 1s ease;
  color: #111;
  border-radius: 0.2rem;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;

}

.nav-container .menu .menu-item::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: inherit;
  z-index: -1;
  background-color: var(--primary);
  transform: scaleX(0);
  transform-origin: 100%;
  transition: transform 0.6s ease;
}

.nav-container .menu .menu-item:hover {
  color: orangered;
  background-color: rgb(249, 200, 109);
}

.nav-container .menu .menu-item:hover::before {
  transform-origin: 0;
  transform: scaleX(1);
}

.nav-container .menu .menu-item:hover .icons {
  transform: scale(1.3);

}

.nav-container .menu .menu-item.active::before {
  transform: scaleX(1);
  background-color: #eee;
}

.nav-container .footer {
  position: absolute;
  bottom: 0.2rem;
  left: 2.5rem;
  text-align: center;
}

.nav-container .footer p {
  font-size: 0.8rem;
  font-weight: 200;
}

.humberger {

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5.5rem;
  text-align: center;
  vertical-align: middle;
  display: none;
}

.humberger span {
  display: inline-block;
  font-size: 2.2rem;
  color: #f1f1f1;
  transform: scale(1.2, 1);
  transition: all 0.5s ease;
  border-radius: 1px;
  cursor: pointer;

}

.active {
  color: wheat;
}

@media (max-width: 1050px) {
  .nav-container .color-wraper .color-btn {
    right: 1rem;
  }

  .humberger span:hover {
    transform: scale(1.5, 1.1);
    color: blue;
  }

}


@media (max-width: 800px) {
  .nav-container .menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0;
    top: -50rem;
    opacity: 0;
    padding: 4rem 0;
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(247, 182, 61);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    transition: all 1s ease-in-out;
    justify-content: center;
    align-items: center;
    z-index: -1;
  }

  .nav-container .menu .menu-wraper {
    box-sizing: border-box;
    width: 18rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: left;

  }

  .nav-container .menu .menu-item {
    font-size: 1.5rem;
    width: 100%;
    box-sizing: border-box;
    margin: 1rem auto;
    color: #f1f1f1;
    font-weight: 300;
    padding: 0.5rem 0 0.5rem 3rem;
  }

  .nav-container .menu .menu-item .icons {
    box-sizing: border-box;
    padding-right: 1rem;
    width: 3rem;
    height: 2rem;
  }

  .nav-container .color-wraper .color-btn {
    right: 12rem;
  }

  .humberger {
    display: block;
  }

  .menu.show-menu {
    top: 3.2rem;
    display: block;
    opacity: 1;

  }
}

@media (max-width: 550px) {
  .nav-container .color-wraper .color-btn {
    right: 10rem;
  }

  .nav-container .color-btn {
    margin: 1rem 1rem;
    height: 2.5rem;
    width: 2.5rem;
    right: 0.5rem;
    top: -0.5rem;

  }
}

@media (max-width: 350px) {
  .nav-container .color-wraper .color-btn {
    right: 8rem;
  }
}