.intro .left-side {
  padding-left: 5rem;

}

.left-side .left-side-wraper {

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2rem;

}

.left-side-wraper .i-intro-name {
  font-size: 1.2rem;
  font-weight: 200;
  color: var(--primary);
  transition: all 1s ease;
  margin-left: 3rem;


}

.left-side-wraper .i-intro-name span {
  margin-left: 2rem;
  font-weight: bold;
  font-size: inherit;
  color: #f1f1f1;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.left-side-wraper .i-intro-name:hover {
  color: #f1f1f1;
}

.left-side-wraper .i-intro-name:hover span {
  opacity: 1;
}

.left-side-wraper .i-name {
  font-size: 1.5rem;
  font-weight: 200;
  white-space: nowrap;
  color: #ddd;
  transition: all 1s ease;
}

.left-side-wraper .i-name:hover {
  color: blue;
  transform: scale(1.1);
}

.left-side-wraper .i-name span {
  color: var(--primary);
}

.left-side-wraper .hero-detail {
  width: 100%;
}

.left-side-wraper .hero-detail p {
  font-size: 1.1rem;
  color: #aaa;
  text-align: justify;
  word-spacing: -2px;
}

.left-side-wraper .hero-btn {
  display: flex;
  margin-top: 2rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.left-side-wraper .hero-btn .hireme {
  font-weight: 100 !important;
  font-size: 1.3rem;
}

.left-side-wraper .hero-btn .i-title {
  height: 49px;
  width: calc(10rem + 5vw);
  overflow: hidden;
  border-radius: 5rem;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, .5);
  box-sizing: border-box;
  border: 2px solid var(--primary);
  background-color: var(--buttonbg);

}

.hero-btn .i-title .i-title-wraper {
  height: 50px;
  animation: moves 10s ease-in-out infinite alternate;
  position: relative;

}

.hero-btn .i-title .title-item {
  height: 50px;
  font-size: calc(0.8rem + 0.65vw);
  box-sizing: border-box;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--primary);
  transition: all 1s ease;

}

.left-side-wraper .hero-btn .i-title:hover .i-title-wraper {
  animation: puause;
}

.left-side-wraper .hero-btn .i-title:hover .i-title-wraper .title-item {
  color: #fff;
  background-color: var(--primary);
}

@keyframes moves {

  50% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(-100px);
  }
}




/*                        right side start      */


.intro .right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;


}

.intro .right-side .i-img-wraper {
  position: relative;
  border-radius: 1rem;

}

.intro .right-side .i-img {
  border-radius: 1rem;
  z-index: 10;
  transition: all 0.8s ease;

}

.intro .right-side .i-img-wraper::before {
  content: 'Front-End Developer';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 95%;
  text-align: center;
  padding-top: 1rem;
  color: blue;
  font-size: 1.1rem;
  box-sizing: border-box;
  background-color: var(--primary);
  bottom: -2vw;
  right: -2vw;
  border-radius: 1rem;
  transition: all 1s ease-in-out;

}

.intro .right-side .i-img-wraper:hover::before {
  bottom: 1rem;
  right: 0;
}

.intro .right-side .i-img:hover {
  margin-top: 3.2rem;
}

.intro .shape-bg {
  clip-path: polygon(42% 0, 100% 0%, 100% 97%, 0 0);
  height: 130%;
  width: 85%;
  background: linear-gradient(280deg, #180394, #f8eeee, var(--primary) 75%);
  position: absolute;
  z-index: -2;
  top: -9rem;
  right: -1rem;
}

/*                    media query section              */


@media (max-width: 1050px) {
  .intro .left-side {
    padding: 0.5rem 0.5rem 2rem 1rem;

  }

  .left-side-wraper .i-intro-name {
    text-align: center;
  }

  .left-side-wraper .i-name {
    text-align: center;
  }

  .left-side .left-side-wraper {
    padding-top: 2rem;
    gap: 1rem;

  }

  .left-side-wraper .hero-btn {
    margin: 0.5rem 0 4rem;
    width: 100%;
  }

  .left-side-wraper .hero-detail {
    width: 100%;
  }

  .left-side-wraper .hero-btn .i-title {
    height: 42px;
  }

  .left-side-wraper .hero-detail {
    width: 100%;
  }

}

@media (max-width: 800px) {


  .intro .right-side {
    order: 1;
    margin: 4rem auto 2rem;
    flex: 1 1;


  }

  .intro .left-side {
    order: 2;
    margin-top: 0rem;
    padding: 0.5rem 0.4rem 2rem;

  }

  .left-side .left-side-wraper {
    padding: 0.3rem;
    gap: 1rem;

  }

  .intro .right-side .i-img-wraper {
    width: 18.5rem;
    height: 18rem;
    border-radius: 50%;
    box-sizing: border-box;

  }

  .intro .right-side .i-img-wraper::before {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }


  .intro .right-side .i-img {
    width: 18rem;
    height: 18rem;
    border-radius: 50%;
    transform: translateX(7px);

  }


  .left-side-wraper .i-intro-name {
    margin: 0.3rem auto;

  }

  .left-side-wraper .i-name {
    margin: 0.2rem auto 0.6rem;
  }

  .left-side-wraper .hero-detail {
    margin-bottom: 2rem;
  }

  .left-side-wraper .hero-btn .hireme {
    font-weight: 100 !important;
    font-size: 1.2rem;
    padding: 0.8rem 6vw;
  }

  .left-side-wraper .hero-btn .i-title {
    width: 30vw;
    height: 45px;
  }

  .hero-btn .i-title .title-item {
    font-size: 3vw;
    font-weight: 300;

  }

  .intro .shape-bg {
    height: 80%;
    width: 95%;
  }

}

@media (max-width: 600px) {
  .left-side-wraper .hero-btn .hireme {
    font-size: 1.2rem;
    padding: 0.8rem 8vw;
  }
}

@media (max-width: 500px) {
  .intro .right-side {
    padding: 0.5rem;

  }

  .intro .right-side .i-img-wraper img {
    width: 100%;
    height: 100%;
  }
}