.skills {
  padding-top: 1rem;
}

/* 
.skills .left-side,
.skills .right-side {
  width: 40%;
} */

.skills h1 {
  text-align: center;
  font-weight: 200;
  margin: 1.5rem auto;
  color: var(--primary);
}

.skills .bar {
  width: 96%;
  margin: 1rem auto;
}

.skills .bar h3 {
  font-weight: 100;
}

.skills .bar .per {
  position: relative;
  background-color: #aaa;
  border-radius: 5px;
  height: 1rem;
  background: linear-gradient(to right, var(--primary), blue);

}

.skills .bar:hover .per {
  animation: skillbar 5s ease-in-out;
}

.skills .bar .per .spanPer {
  position: absolute;
  color: white;
  width: 2rem;
  height: 2rem;
  background-color: var(--primary);
  border-radius: 50%;
  right: -0.9rem;
  top: -2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, var(--primary), blue);
  z-index: 10;

}

.skills .bar .per .spanPer::after {
  content: '';
  position: absolute;
  width: 0.1rem;
  height: 0.5rem;
  z-index: -1;
  background: linear-gradient(to right, var(--primary), blue);
  bottom: -0.5rem;
}



@keyframes skillbar {
  0% {
    width: 100%;
  }

  35% {
    width: 10%;
  }

  100% {
    width: 100%;
  }
}

@media (max-width:1050px) {
  .skills {
    padding: 3rem 0.5rem 3rem 1.5rem;
    gap: 0.2rem;
  }


}


@media (max-width: 730px) {
  .skills .bar {
    width: 100%;
    text-align: left;
  }

  .skills h1 {
    margin: 1rem auto;
  }

}