.contact {
  box-sizing: border-box;
  background-color: #444;
  color: antiquewhite;
  padding-top: 1rem;

}

.contact h2 {
  text-align: center;
  margin: 0.5rem auto 1rem;
  font-weight: 200;
  color: var(--primary);
}

.contact .left-side .map {
  border-radius: 1rem;
  height: 23rem;
  width: 95%;
  margin: 0.5rem auto;
  border: 2px solid var(--primary);
  box-sizing: border-box;
  padding: 0;
}

.contact .left-side .map-frame {
  border-radius: 1rem;
  width: 100%;
  height: 100%;


}

/*                                right side             */


.contact .right-side h2 {
  margin-bottom: 0.2rem;
}

.contact .right-side .forms {
  padding: 0.5rem;

}

.contact .right-side .forms .label-box {
  line-height: 2rem;
  border-bottom: 1px solid #999;
  margin-top: 0.4rem;
  text-align: left;

}

.contact .right-side .forms label {
  font-size: 1rem;
  margin-right: 1rem;
  font-weight: 200;

}

.contact .right-side .forms input {
  font-size: 1.1rem;
  margin-right: 1rem;
  background: transparent;
  border: none;
  outline: none;
  color: #f2f2f2;
  width: 65%;

}

.contact .right-side .forms .label-msg {
  font-size: 1.2rem;
  font-weight: 200;
  margin: 1rem 0 0.5rem;
  line-height: 2.5rem;
  text-align: center;

}

.contact .right-side .forms textarea {
  height: 8rem;
  width: 100%;
  border-bottom: 1px solid #999;
  border-radius: 0.5rem;
  box-sizing: border-box;
  background: transparent;
  font-size: 1.2rem;
  color: #f2f2f2;
  outline: none;
  padding: 1rem;
  margin-bottom: 1rem;

}

.contact .right-side .forms .sender {
  font-size: calc(0.75rem + 0.4vw);
  margin-left: 0.5rem;
  margin-bottom: 2.5rem;
  padding: 0.3rem 1.5rem;
}

.contact .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 115%;
  height: 2.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 12rem 0 0;
  background: linear-gradient(to bottom, #444, var(--primary));
  color: azure;
  font-weight: 200;
  box-sizing: border-box;
  z-index: 10;
  box-shadow: 5px 5px 10px 5px #111;
}

/*              media query section         */



@media (max-width: 800px) {
  .contact {
    padding: 0.5rem;
  }

  .contact .left-side {
    margin-top: 3rem;

  }

  .contact .right-side {
    padding: 0.5rem;
  }

  .contact .right-side h2 {
    margin: 1rem auto;
  }

}

@media (max-width: 730px) {
  .contact .right-side .forms .sender {
    margin-top: 1rem;
  }

  .contact .left-side .map-frame {
    width: 95%;
  }

  .contact .left-side .map {
    border: none;
    height: 24rem;
    margin: 0.5rem auto;

  }


}