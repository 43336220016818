@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary: #f48805;
  --secondary: #4942e4;
  --textdark: #222;
  --textlight: #f2f2f2;
  --buttonbg: #444;
}

body {
  width: 100vw;
  background: #222;
  font-family: 'Poppins', sans-serif;
  color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

html {
  font-size: clamp(0.65rem, 2.5vw, 0.8rem);
  /* scroll-behavior: smooth; */

}

.app {
  width: 100%;
  max-width: 1340px;
  position: relative;
  margin: 0 auto;

}

.section {
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  gap: 0.5rem;
  position: relative;
  padding: 0 1rem;

}

.left-side {
  box-sizing: border-box;
  flex: 1 1;
  padding: 0 2rem;
  margin: 1rem;
}

.right-side {
  box-sizing: border-box;
  flex: 1 1;
  padding: 0 1rem 0.5rem 0.2rem;
  margin: 1rem;

}

button {
  padding: 0.6rem 2.4vw;
  border: 2px solid var(--primary);
  background-color: var(--buttonbg);
  border-radius: 4rem;
  cursor: pointer;
  color: var(--primary);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, .5);
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  font-size: calc(0.7rem + 0.5vw);
  z-index: 5;
}

button::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 4rem;
  left: 0;
  top: 0;
  background-color: var(--primary);
  transform: scaleX(0);
  transform-origin: 100%;
  transition: transform 0.6s ease;
  z-index: -1;
}

button:hover::before {
  transform-origin: 0;
  transform: scaleX(1);

}

button:hover {
  color: #111;
}

@media (max-width: 1050px) {

  html {
    font-size: 85%;
  }

  .section {
    min-height: fit-content;
    padding: 2rem 1rem 3rem;
  }

  button {
    padding: 0.5rem 2.2vw;
  }

  .left-side,
  .right-side {
    padding: 1rem;

  }

}

@media (max-width: 800px) {
  .section {
    flex-direction: column;
    height: auto;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    align-items: center;
    min-height: auto;
    padding: 1rem;
    gap: 0.5rem;

  }

  .left-side {
    padding: 0 0.2rem 0.3rem 0.5rem;

  }

  html {
    font-style: 75%;

  }

  .left-side,
  .right-side {
    width: 500px;
    box-sizing: border-box;
    text-align: center;
    padding: 0.5rem;
    margin: 0;
  }

  button {
    padding: 0.4rem 2vw;
  }

}

@media (max-width: 550px) {

  .left-side,
  .right-side {
    width: 440px;
    box-sizing: border-box;

  }

  html {
    font-size: 65%;
  }
}

@media (max-width: 500px) {

  .left-side,
  .right-side {
    max-width: 400px;

  }
}

@media (max-width: 450px) {

  .left-side,
  .right-side {
    max-width: 400px;

  }
}

@media (max-width: 400px) {

  .left-side,
  .right-side {
    max-width: 300px;

  }
}